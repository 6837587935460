import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthGoogleService } from '@app/core/services/auth-google.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthCallbackResolver implements Resolve<any> {
  constructor(
    private authGoogleService: AuthGoogleService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (!isPlatformBrowser(this.platformId)) {
      console.warn('Callback resolver running in non-browser environment');
      return of(null);
    }

    try {
      const callbackData = this.authGoogleService.getProfile();
      console.log('Processing callback data:', callbackData);

      if (callbackData && callbackData.profile) {
        this.authGoogleService.loginCallback(callbackData);
        return of(callbackData);
      } else {
        console.warn('No valid profile data found in callback');
        // 重定向到登录页面
        this.router.navigate(['/']);
        return of(null);
      }
    } catch (error) {
      console.error('Error processing Google auth callback:', error);
      // 重定向到登录页面
      this.router.navigate(['/']);
      return of(null);
    }
  }
}
