/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import * as packageInfo from '../package.json';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

// only load in prod mode
if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      // Registers and configures the Replay integration
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        networkDetailAllowUrls: ['gugudata.io'],
      }),
    ],
    // Session Replay Options
    replaysSessionSampleRate: 0.1, // Sample 10% of sessions
    replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Release version
    release: packageInfo.version,
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
