import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { HttpUtilsService } from '../http/http-utils.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {
  private oAuthService = inject(OAuthService);
  private router = inject(Router);

  constructor(
    private httpUtils: HttpUtilsService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // 使用 setTimeout 确保在浏览器环境完全准备好后初始化
      setTimeout(() => {
        this.initConfiguration();
      }, 0);
    }
  }

  public initConfiguration() {
    try {
      const authConfig: AuthConfig = {
        issuer: 'https://accounts.google.com',
        strictDiscoveryDocumentValidation: false,
        clientId:
          '544534011813-uj78bk7nfic4olgf1qtf2ktsa6kkh1qf.apps.googleusercontent.com',
        redirectUri: window.location.origin + '/auth/google-auth-callback',
        scope: 'openid profile email',
      };

      this.oAuthService.configure(authConfig);
      this.oAuthService.setupAutomaticSilentRefresh();
      this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    } catch (error) {
      console.error('Failed to initialize OAuth configuration:', error);
    }
  }

  login() {
    if (!isPlatformBrowser(this.platformId)) {
      console.warn('Login attempted in non-browser environment');
      return;
    }

    try {
      if (!this.oAuthService.hasValidAccessToken()) {
        console.log('Reinitializing OAuth configuration...');
        this.initConfiguration();
      }
      this.oAuthService.initImplicitFlow();
    } catch (error) {
      console.error('Failed to initialize OAuth flow:', error);
    }
  }

  logout() {
    this.oAuthService.revokeTokenAndLogout();
    this.oAuthService.logOut();
  }

  getProfile() {
    const profile = this.oAuthService.getIdentityClaims();
    const idToken = this.oAuthService.getIdToken();
    console.log('profile', profile);
    console.log('idToken', idToken);
    return { profile, idToken };
  }

  getToken() {
    return this.oAuthService.getAccessToken();
  }

  loginCallback(callbackData: any) {
    if (!callbackData || !callbackData.profile) {
      console.warn('Invalid callback data received');
      return;
    }

    this.httpUtils.post('/auth/google-auth-callback', callbackData).subscribe({
      next: (response: any) => {
        if (response.accessToken && response.refreshToken) {
          this.authService.setUserId(response.userId);
          this.authService.setAccessToken(response.accessToken);
          this.authService.setRefreshToken(response.refreshToken);
          this.authService.initializeUserData(); // Ensure user data is updated after login callback
          let returnUrl = this.router.parseUrl(this.router.url).queryParams[
            'return_url'
          ];
          if (!returnUrl) {
            returnUrl = '/';
          }
          this.router.navigateByUrl(returnUrl);
        } else {
          console.warn('Incomplete response received from server');
        }
      },
      error: (error) => {
        console.error('Login callback failed:', error);
      },
      complete: () => {},
    });
  }
}
