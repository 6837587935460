<footer class="bg-dark">
  <div class="container pb-1 pb-lg-5">
    <div class="row content-space-t-2">
      <div class="col-lg-3 mb-7 mb-lg-0">
        <!-- Logo -->
        <div class="mb-5">
          <a class="navbar-brand" routerLink="/" aria-label="Space">
            <img
              class="navbar-brand-logo"
              src="assets/svg/logos/logo-white.svg"
              alt="gugudata logo"
            />
          </a>
        </div>
        <!-- End Logo -->

        <!-- List -->
        <ul class="list-unstyled list-py-1">
          <li>
            <a
              class="link-sm link-light"
              href="#"
              title="1111B S Governors Ave STE 3590, Dover, DE 19904"
            >
              <i class="bi-geo-alt-fill me-1"></i>
              Dover, DE 19904, United States
            </a>
          </li>
          <li>
            <a class="link-sm link-light" href="mailto:support@gugudata.io"
              ><i class="bi bi-envelope-fill me-2"></i
              >support&#64;gugudata.io</a
            >
          </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Col -->

      <div class="col-sm mb-7 mb-sm-0">
        <h5 class="text-white mb-3">Company</h5>

        <!-- List -->
        <ul class="list-unstyled list-py-1 mb-0">
          <li><a class="link-sm link-light" href="/aboutus">About</a></li>
          <li><a class="link-sm link-light" href="/aboutus">Support</a></li>
          <li>
            <a
              class="link-sm link-light"
              target="_blank"
              href="https://gugudata.statuspage.io/"
              >Status&nbsp;<i class="bi-box-arrow-up-right small ms-1"></i
            ></a>
          </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Col -->

      <div class="col-sm mb-7 mb-sm-0">
        <h5 class="text-white mb-3">Documentation</h5>

        <!-- List -->
        <ul class="list-unstyled list-py-1 mb-0">
          <li>
            <a class="link-sm link-light" routerLink="/details/html2pdf"
              >Docs</a
            >
          </li>
          <li>
            <a class="link-sm link-light" routerLink="/details/html2pdf"
              >API Reference</a
            >
          </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Col -->

      <div class="col-sm mb-7 mb-sm-0">
        <h5 class="text-white mb-3">Features</h5>

        <!-- List -->
        <ul class="list-unstyled list-py-1 mb-0">
          <li>
            <a class="link-sm link-light" routerLink="/details/html2pdf"
              >Release Notes</a
            >
          </li>
          <li>
            <a class="link-sm link-light" routerLink="/details/html2pdf"
              >Integrations</a
            >
          </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Col -->

      <div class="col-sm">
        <h5 class="text-white mb-3">Resources</h5>

        <!-- List -->
        <ul class="list-unstyled list-py-1 mb-5">
          <li>
            <a class="link-sm link-light" href="/aboutus"
              ><i class="bi-question-circle-fill me-1"></i> Help</a
            >
          </li>
          <li>
            <a class="link-sm link-light" routerLink="/dashboard/account-info"
              ><i class="bi-person-circle me-1"></i> Your Account</a
            >
          </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->

    <div class="border-top border-white-10 my-7"></div>

    <div class="row mb-7">
      <div class="col-sm mb-3 mb-sm-0">
        <!-- Socials -->
        <ul class="list-inline list-separator list-separator-light mb-0">
          <li class="list-inline-item">
            <a class="link-sm link-light" href="/privacy-policy"
              >Privacy &amp; Policy</a
            >
          </li>
          <li class="list-inline-item">
            <a class="link-sm link-light" href="/privacy-policy">Terms</a>
          </li>
        </ul>
        <!-- End Socials -->
      </div>

      <div class="col-sm-auto">
        <!-- Socials -->
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <!-- Button Group -->
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-soft-light btn-xs"
                id="footerSelectLanguage"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-dropdown-animation
              >
                <span class="d-flex align-items-center">
                  <img
                    class="avatar avatar-xss avatar-circle me-2"
                    src="assets/flags/1x1/us.svg"
                    alt="Image description"
                    width="16"
                  />
                  <span>English (US)</span>
                </span>
              </button>
            </div>
            <!-- End Button Group -->
          </li>
        </ul>
        <!-- End Socials -->
      </div>
    </div>

    <!-- Copyright -->
    <div class="w-md-85 text-lg-center mx-lg-auto">
      <p class="text-white-50 small">&copy;&nbsp;2024-2025 GuGuData, LLC</p>
      <p class="text-white-50 small">
        When you visit or interact with our sites, services or tools, we or our
        authorised service providers may use cookies for storing information to
        help provide you with a better, faster and safer experience and for
        marketing purposes.
      </p>
    </div>
    <!-- End Copyright -->
  </div>
  <!-- Go To -->
  <a
    class="js-go-to go-to position-fixed"
    href="javascript:;"
    style="visibility: hidden"
    data-hs-go-to-options='{
"offsetTop": 700,
"position": {
"init": {
  "right": "2rem"
},
"show": {
  "bottom": "2rem"
},
"hide": {
  "bottom": "-2rem"
}
}
}'
  >
    <i class="bi-chevron-up"></i>
  </a>
  <script src="https://gugudata.statuspage.io/embed/script.js"></script>
</footer>
