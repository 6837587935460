// src/app/core/services/auth.service.ts
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AccountUser } from '@app/models/interface/account-user.interface';
import { HttpUtilsService } from '@core/http/http-utils.service';
import { SigninRequest, SignupRequest } from '@models/signup-request.model';
import { BehaviorSubject, Observable, map, of, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedInSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  _userData = new BehaviorSubject<AccountUser | null>(null);

  _userData$ = isPlatformBrowser(this.platformId)
    ? this.getUserInfo().pipe(
        map((data) => {
          this._userData.next(data);
          return data;
        }),
        shareReplay(1)
      )
    : of(null);

  constructor(
    private httpUtils: HttpUtilsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('accessToken');
      this.isLoggedInSubject = new BehaviorSubject<boolean>(!!token);
    }
  }

  initializeUserData() {
    if (isPlatformBrowser(this.platformId)) {
      this.getUserInfo().subscribe((data) => {
        this._userData.next(data);
      });
    }
  }

  get userData() {
    return this._userData.asObservable();
  }

  fetchUserInfo(data: AccountUser) {
    this._userData.next(data);
  }

  get isLoggedIn(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  setRefreshToken(refreshToken: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }

  setAccessToken(accessToken: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('accessToken', accessToken);
      this.isLoggedInSubject.next(true);
      this.initializeUserData(); // Ensure user data is updated after setting the access token
    }
  }

  setUserId(userId: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('userId', userId);
    }
  }

  getUserId(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('userId') || '';
    }
    return '';
  }

  signup(signupRequest: SignupRequest): Observable<any> {
    return this.httpUtils.post(`/users/accounts`, signupRequest);
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('userId');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.isLoggedInSubject.next(false);
    }
  }

  signin(signinRequest: SigninRequest): Observable<any> {
    return this.httpUtils.post(`/auth/login`, signinRequest);
  }

  getUserInfo(): Observable<AccountUser | null> {
    if (isPlatformBrowser(this.platformId)) {
      let userId = this.getUserId();
      if (userId) {
        return this.httpUtils.get(`/users/accounts/${userId}`);
      }
    }
    return of(null);
  }

  updateUserInfo(userId: string, userInfo: any): Observable<any> {
    return this.httpUtils.put(`/users/accounts/${userId}`, userInfo);
  }

  deleteUser(userId: string): Observable<any> {
    return this.httpUtils.delete(`/users/accounts/${userId}`);
  }

  verifyToken(token: any): Observable<any> {
    return this.httpUtils.post(`/users/accounts/token`, { token });
  }

  refreshToken(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpUtils.post(`/auth/refresh-token`, {
        userId: localStorage.getItem('userId') || '',
        refreshToken: localStorage.getItem('refreshToken') || '',
      });
    }
    return of(null);
  }

  checkLoginStatus(): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('accessToken');
      const isLoggedIn = !!token;
      return of(isLoggedIn);
    }
    return of(false);
  }
}
